import domReady from '@wordpress/dom-ready';

domReady(() => {
  const anchorsBanner = document.querySelector('.anchors-banner__links');
  const sections = document.querySelectorAll('*[id]');
  const darkSections = document.querySelectorAll(
    '.has-dark-grey-background-color, .page-slider'
  );
  const anchorsLinks = document.querySelectorAll(
    '.anchors-banner__links .link'
  );
  const hasOffset = window.scrollY !== undefined;
  const docBody =
    document.documentElement || document.body.parentNode || document.body;
  let scrollTop = hasOffset ? window.scrollY : docBody.scrollTop;
  let stop;

  const elementIsVisibleInViewport = (el, partiallyVisible = false) => {
    const { top, bottom } = el.getBoundingClientRect();
    const elTop = top + el.offsetHeight / 2;
    const elBottom = bottom - el.offsetHeight / 2;
    const { innerHeight } = window;

    if (partiallyVisible) {
      return (
        (elTop > 0 && elTop < innerHeight) ||
        (elBottom > 0 && elBottom < innerHeight) ||
        (elTop < 0 && elBottom > innerHeight)
      );
    }

    return top >= 0 && bottom <= innerHeight;
  };

  if (0 < anchorsLinks.length && 0 < sections.length) {
    function checkActiveAnchor() {
      sections.forEach((section) => {
        const sectionID = section.getAttribute('id');
        const anchor = document.querySelector(
          '.anchors-banner__links .link[data-anchor="' + sectionID + '"'
        );

        if (anchor) {
          if (elementIsVisibleInViewport(section, true)) {
            anchor.classList.add('active');
          } else {
            anchor.classList.remove('active');
          }
        }
      });
    }

    function checkBannerVisibility() {
      if (scrollTop >= stop) {
        anchorsBanner.classList.add('is-visible');
      } else {
        anchorsBanner.classList.remove('is-visible');
      }
    }

    function checkBannerContrast() {
      let isDarkVisible = false;

      if (0 < darkSections.length) {
        const bannerTop =
          anchorsBanner.getBoundingClientRect().top +
          document.documentElement.scrollTop;
        const bannerBottom = bannerTop + anchorsBanner.offsetHeight;

        darkSections.forEach((darkSection) => {
          const sectionHeight = darkSection.offsetHeight;
          const sectionPosition = darkSection.offsetTop;

          const sectionTop = sectionPosition;
          const sectionBottom = sectionPosition + sectionHeight;

          if (bannerBottom > sectionTop && bannerTop < sectionBottom) {
            isDarkVisible = true;
          }
        });
      }

      if (isDarkVisible) {
        anchorsBanner.classList.add('dark-contrast');
      } else {
        anchorsBanner.classList.remove('dark-contrast');
      }
    }

    anchorsLinks.forEach((link) => {
      link.addEventListener('focus', () => {
        if (!anchorsBanner.classList.contains('is-visible')) {
          anchorsBanner.classList.add('is-visible');
        }
      });
    });

    stop = anchorsBanner.offsetTop - 40;
    checkBannerVisibility();
    checkActiveAnchor();
    checkBannerContrast();

    window.addEventListener('scroll', function () {
      scrollTop = hasOffset ? window.scrollY : docBody.scrollTop;
      checkBannerVisibility();
      checkBannerContrast();
      this.setTimeout(function () {
        checkActiveAnchor();
      }, 200);
    });
  }
});
